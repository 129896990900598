import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import Zoom from 'react-medium-image-zoom'
import { mediaQueries, palette } from '~components/homepage/styled/constants'
import { findImages } from '~components/homepage/utils'
import { ArticlePicture, ArticleSourceLink } from '~src/types/article'
import {
  DefaultImage,
  ResponsiveImage,
} from '~components/homepage/styled/shared'
import 'react-medium-image-zoom/dist/styles.css'
import { ImagesQueryData } from '~src/types/graphql-types'

export const Title = styled.div`
  font-size: 25px;
  color: ${palette.darkBlue};
  font-family: 'Averta-Bold', sans-serif;
  text-align: center;
`

export const Paragraph = styled.div`
  font-size: 13px;
  color: ${palette.darkBiege};
  font-family: 'Averta-Regular', sans-serif;
  margin-top: 25px;
  padding: 0px 60px;
  line-height: 23px;
`

export const Subtitle = styled.div<{ gray?: boolean }>`
  font-size: 13px;
  color: ${({ gray }): string => (gray ? palette.darkBiege : palette.darkBlue)};
  font-family: 'Averta-Bold', sans-serif;
  margin-top: 60px;
  padding: 0px 60px;
`

export const Info = styled.div`
  font-family: 'Averta-RegularItalic', sans-serif;
  font-size: 15px;
  color: ${palette.darkBlue};
  max-width: 250px;
  text-align: center;
  margin: 0 auto;
  margin-top: 35px;
`

const PictureDescription = styled.div<{ width?: string }>`
  font-size: 10px;
  font-family: 'Averta-Regular', sans-serif;
  color: ${palette.darkBiege};
  margin: ${({ width }): string => (width ? '0 auto' : 'inherit')};
  margin-top: 10px;
  text-align: left;
  width: ${({ width }): string => width || 'unset'};

  @media (max-width: ${mediaQueries.medium}) {
    & {
      padding: 0px 10px;
      width: 100%;
    }
  }
`

export const Divider = styled.div`
  width: '100%';
  height: 1px;
  background: ${palette.darkBlue};

  margin: 0px 60px;
  margin-top: 70px;
`

const SourceLinkWrapper = styled.div`
  font-size: 13px;
  color: ${palette.darkBiege};
  font-family: 'Averta-Regular', sans-serif;
  margin-top: 25px;
  padding: 0px 60px;
  line-height: 23px;
`

const Link = styled.a`
  color: ${palette.darkBiege} !important;
  text-decoration: underline !important;
`

const PictureWrapper = styled.div`
  margin-top: 40px;

  & > div:not(.image-description) {
    width: 100%;
  }

  img {
    margin: 0 auto;
  }
`

export const Picture = ({
  src,
  height,
  width,
  description,
  asImage = false,
  zoomable = true,
}: ArticlePicture): ReactElement => {
  const data = useStaticQuery<ImagesQueryData>(
    graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              childImageSharp {
                fluid(quality: 100) {
                  originalName
                  ...GatsbyImageSharpFluid
                }
              }
              name
            }
          }
        }
      }
    `
  )

  const imageSource = findImages(data, [src])

  const img = asImage ? (
    <DefaultImage
      style={{
        height,
        width,
      }}
      fluid={imageSource[0]?.node.childImageSharp.fluid}
    />
  ) : (
    <ResponsiveImage
      src={imageSource[0]?.node.childImageSharp.fluid.src}
      height={height}
      width={width}
      style={{ margin: '0 auto' }}
    />
  )

  return (
    <PictureWrapper
      data-sal="fade"
      data-sal-delay="200"
      data-sal-duration="800"
      data-sal-easing="ease"
    >
      {zoomable ? (
        <Zoom>{img}</Zoom>
      ) : (
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {' '}
          {img}{' '}
        </div>
      )}

      <PictureDescription className="image-description" width={width}>
        {description}
      </PictureDescription>
    </PictureWrapper>
  )
}

export const SourceLink = ({
  href,
  preffix,
}: ArticleSourceLink): ReactElement => {
  return (
    <SourceLinkWrapper
      data-sal="fade"
      data-sal-delay="200"
      data-sal-duration="500"
      data-sal-easing="ease"
    >
      {preffix}
      {'  '}
      <Link href={href} target="_blank" rel="noopener noreferrer">
        {href}
      </Link>
    </SourceLinkWrapper>
  )
}

export const StyledDot = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 100px;
`
